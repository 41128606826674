import React, { Fragment, useEffect, useState } from "react";
import {
    Alert,
    AlertTitle,
    Box,
    Card,
    Chip,
    Container,
    Divider,
    Grid,
    MenuItem,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import { GoogleMap } from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useParams, useHistory } from "react-router-dom";
import { formatPhoneNumber } from "react-phone-number-input";
import cx from "classnames";

import { getPublicImage, isMFRGroup } from "../../utils/helpers";
import { PROVIDER_PROFILE_ROUTE, PROVIDER_SEARCH_ROUTE } from "../../utils/routes";
import { createPhoneDataPoint, getClinic, updateClinicProfile } from "../../app/actions";
import { clinicFragment } from "../../app/fragments/groups";
import useResponsive from "../../hooks/useResponsive";

import Button from "../button/Button";
import ButtonLink from "../button/ButtonLink";
import ButtonMain from "../button/ButtonMain";
import EditBioModal from "./EditBioModal";
import EditClinicContactModal from "./EditClinicContactModal";
import EditClinicImagesModal from "./EditClinicImagesModal";
import EditClinicInfoModal from "./EditClinicInfoModal";
import EditCredentialsModal from "./EditCredentialsModal";
import EditKeywordsModal from "./EditKeywordsModal";
import Image from "../image/Image";
import ImageCarousel from "../image/ImageCarousel";
import MenuPopover from "../minimal/MenuPopover";
import MFRHeader from "../../layouts/dashboard/header/MFRHeader";
import Modal from "../modal/Modal";
import Page from "../minimal/Page";
import ProviderContactForm from "./ProviderContactForm";
import ShareButton from "../share_button/ShareButton";
import Spinner from "../spinner/Spinner";
import SvgIconStyle from "../minimal/SvgIconStyle";
import { toast } from "react-toastify";
import TSAvatar from "../avatar/TSAvatar";
import TSMarker from "../marker/TSMarker";

export default function ClinicProfile() {
    const dispatch = useDispatch();
    const history = useHistory();
    const theme = useTheme();
    const { clinicId } = useParams();
    const { clinic } = useSelector(({ mfr }) => mfr);
    const me = useSelector(({ me }) => me);
    const mfr = useSelector(({ mfr }) => mfr);

    const [showContactForm, setShowContactForm] = useState(false);
    const [canEditProfile, setCanEditProfile] = useState(false);
    const [showEditClinicImagesModal, setShowEditClinicImagesModal] = useState(false);
    const [showEditClinicInfoModal, setShowEditClinicInfoModal] = useState(false);
    const [showEditClinicBioModal, setShowEditClinicBioModal] = useState(false);
    const [showEditClinicContactModal, setShowEditClinicContactModal] = useState(false);
    const [showEditCredentialsModal, setShowEditCredentialsModal] = useState(false);
    const [showEditKeywordsModal, setShowEditKeywordsModal] = useState(false);
    const [showUnpublishedModal, setShowUnpublishedModal] = useState(false);
    const [center, setCenter] = useState(null);
    const [showPhone, setShowPhone] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [showBlurb, setShowBlurb] = useState(false);
    const [metaKeywords, setMetaKeywords] = useState("");

    const isMobile = useResponsive("down", "lg");
    const isMFR = isMFRGroup(me.group);
    const mfrDomain =
        window.location.hostname.includes("mfrtherapists.com") || window.location.hostname.includes("mfrhealth.com");

    const containerStyle = {
        width: "100%",
        height: isMobile ? "350px" : "400px",
        borderRadius: "20px",
    };

    useEffect(() => {
        async function init() {
            const result = await dispatch(getClinic(clinicId, { ...clinicFragment }));
            const { error, data } = result;

            if (error) {
                history.push(PROVIDER_SEARCH_ROUTE);
            } else {
                if (data.group && data.group.access_level && data.group.access_level >= 40) {
                    setCanEditProfile(true);
                    setShowPhone(true);
                }
                setCenter({ lat: Number(data.latitude), lng: Number(data.longitude) });
            }

            const windowSlug = window.location.pathname.split("/").pop();
            if (windowSlug !== data.slug) history.push(PROVIDER_SEARCH_ROUTE);

            const defaultKeywords = [];
            defaultKeywords.push(`${data.title}`);
            data &&
                data.providers.length > 0 &&
                data.providers.map((provider) =>
                    defaultKeywords.push(
                        `${provider.user && provider.user.first_name ? provider.user.first_name : ""} ${
                            provider.user && provider.user.last_name ? provider.user.last_name : ""
                        }`
                    )
                );
            const userKeywords = data.keywords ? data.keywords.split(",") : [];
            const keywords = [...defaultKeywords, ...userKeywords];

            setMetaKeywords(keywords);
        }

        init();
    }, []); // eslint-disable-line

    const toggleShowPhone = () => {
        if (clinic && clinic.owner_profile_id) dispatch(createPhoneDataPoint(clinic.owner_profile_id));
        setShowPhone(true);
    };

    const toggleContactForm = () => {
        setShowContactForm(!showContactForm);
    };

    const handleOpenMenu = (event) => {
        setOpenMenu(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenu(null);
    };

    const toggleStatus = () => {
        dispatch(updateClinicProfile(clinic.id, { public: !clinic.public }));
    };

    const toggleBlurb = () => {
        setShowBlurb(true);
    };

    const menuActions = (
        <>
            <MenuItem
                onClick={() => {
                    toggleStatus();
                    handleCloseMenu();
                }}
                className="w-100 p-2"
            >
                <div className="d-flex justify-content-between w-100">
                    {clinic && clinic.public ? "Unpublish Profile" : "Publish profile"}
                    <SvgIconStyle
                        src={getPublicImage(clinic && clinic.public ? "ic_eye_off.svg" : "ic_eye.svg")}
                        className="ts-edit-user-icon"
                        alt="Edit User"
                    />
                </div>
            </MenuItem>
        </>
    );

    const showEditProfileModal = (component) => () => {
        switch (component) {
            case "clinic-credentials":
                setShowEditCredentialsModal(true);
                break;
            case "clinic-bio":
                setShowEditClinicBioModal(true);
                break;
            case "contact":
                setShowEditClinicContactModal(true);
                break;
            case "images":
                setShowEditClinicImagesModal(true);
                break;
            case "keywords":
                setShowEditKeywordsModal(true);
                break;
            default:
                setShowEditClinicInfoModal(true);
                break;
        }
    };

    const closeEditModal = () => {
        if (showEditClinicImagesModal) setShowEditClinicImagesModal(false);
        if (showEditClinicInfoModal) setShowEditClinicInfoModal(false);
        if (showEditClinicBioModal) setShowEditClinicBioModal(false);
        if (showEditClinicContactModal) setShowEditClinicContactModal(false);
        if (showEditCredentialsModal) setShowEditCredentialsModal(false);
        if (showEditKeywordsModal) setShowEditKeywordsModal(false);

        if (!clinic.public) {
            setShowUnpublishedModal(true);
        }
    };

    const publishClinic = async () => {
        const body = {
            public: true,
        };

        if (clinic && clinic.providers && clinic.providers.length === 0)
            body.providers = [me && me.provider_profiles && me.provider_profiles[0] && me.provider_profiles[0].id];
        const result = dispatch(updateClinicProfile(clinic.id, body, { ...clinicFragment }));
        const { error } = result;

        if (error) {
            toast.error("Error publishing clinic");
        } else {
            toast.success("Clinic published");
        }

        setShowUnpublishedModal(false);
    };

    const closeUnpublishModal = () => {
        setShowUnpublishedModal(false);
    };

    const EditButton = ({ section }) => {
        return (
            <ButtonLink onClick={showEditProfileModal(section)}>
                <SvgIconStyle
                    src={getPublicImage("ic_edit.svg")}
                    sx={{
                        color: theme.palette.tertiary.main,
                        "&:hover": {
                            color: `${theme.palette.tertiary.darker} !important`,
                        },
                    }}
                    className="hover-pointer"
                />
            </ButtonLink>
        );
    };

    const PhoneNumberLink = ({ value = "" }) => {
        const formatted = formatPhoneNumber(value);
        const isValid = formatted.length > 0;

        // Display the phone number as telephone link
        if (isValid) {
            return (
                <a href={`tel:${value}`} className="ml-1">
                    {formatted}
                </a>
            );
        }

        // Display the phone number a plain text
        if (value.length > 0) {
            return <span className="ml-1">{value}</span>;
        }

        return undefined;
    };

    const ContactInfo = () => {
        return (
            <>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5" sx={{ fontWeight: 700, color: theme.palette.grey[800], mb: 1 }}>
                        Clinic
                    </Typography>
                    {canEditProfile && <EditButton section="contact" />}
                    {!canEditProfile && clinic && clinic.providers && clinic.providers.length > 0 && (
                        <Stack direction="row" spacing={1} alignItems="center" sx={{ my: 0.5 }}>
                            {/* <SvgIconStyle src={getPublicImage("ic_mail.svg")} sx={{ color: theme.palette.grey[600] }} /> */}
                            <Typography
                                variant="body2"
                                sx={{ display: "flex", alignItems: "center", color: theme.palette.grey[600] }}
                            >
                                {/* Email:{" "} */}
                                <ButtonMain
                                    analyticsClass="button__contact-provider-email"
                                    onClick={toggleContactForm}
                                    className="ts-contact-button ml-1"
                                >
                                    Contact now
                                </ButtonMain>
                            </Typography>
                        </Stack>
                    )}
                </Stack>
                <Stack sx={{ backgroundColor: theme.palette.grey[100], borderRadius: "20px", p: 2 }}>
                    {clinic.phone && (
                        <Stack direction="row" spacing={1} alignItems="center" sx={{ my: 0.5 }}>
                            <SvgIconStyle
                                src={getPublicImage("ic_phone.svg")}
                                sx={{ color: theme.palette.grey[600] }}
                            />
                            <Typography
                                variant="body2"
                                sx={{ display: "flex", alignItems: "center", color: theme.palette.grey[600] }}
                            >
                                Phone:{" "}
                                {showPhone ? (
                                    <PhoneNumberLink value={clinic.phone} />
                                ) : (
                                    <ButtonLink
                                        analyticsClass="button__clinic-show-phone"
                                        onClick={toggleShowPhone}
                                        className="ts-contact-button ml-1"
                                    >
                                        Show phone
                                    </ButtonLink>
                                )}
                            </Typography>
                        </Stack>
                    )}
                    {showPhone && clinic.alt_phone && (
                        <Stack direction="row" spacing={1} alignItems="center" sx={{ my: 0.5 }}>
                            <SvgIconStyle
                                src={getPublicImage("ic_phone.svg")}
                                sx={{ color: theme.palette.grey[600] }}
                            />
                            <Typography
                                variant="body2"
                                sx={{ display: "flex", alignItems: "center", color: theme.palette.grey[600] }}
                            >
                                Alt. Phone: <PhoneNumberLink value={clinic.alt_phone} />
                                {clinic.vanity_phone ? <PhoneNumberLink value={clinic.vanity_phone} /> : null}
                            </Typography>
                        </Stack>
                    )}
                    {clinic.url && (
                        <Stack direction="row" spacing={1} alignItems="center" sx={{ my: 0.5 }}>
                            <SvgIconStyle src={getPublicImage("ic_web.svg")} sx={{ color: theme.palette.grey[600] }} />
                            <a href={clinic.url} target="_blank" rel="noopener noreferrer" className="text-main-alt">
                                <Typography variant="body2">{clinic.title}'s Website</Typography>
                            </a>
                        </Stack>
                    )}
                    {clinic.facebook && (
                        <Stack direction="row" spacing={1} alignItems="center" sx={{ my: 0.5 }}>
                            <SvgIconStyle
                                src={getPublicImage("ic_facebook_logo.svg")}
                                sx={{ color: theme.palette.grey[600] }}
                            />
                            <a
                                href={clinic.facebook}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="ts-contact-button text-main-alt ml-2"
                            >
                                <Typography variant="body2" sx={{ display: "flex", alignItems: "center" }}>
                                    Facebook
                                </Typography>
                            </a>
                        </Stack>
                    )}
                    {clinic.instagram && (
                        <Stack direction="row" spacing={1} alignItems="center" sx={{ my: 0.5 }}>
                            <SvgIconStyle
                                src={getPublicImage("ic_instagram_logo.svg")}
                                sx={{ color: theme.palette.grey[600] }}
                            />
                            <a
                                href={clinic.instagram}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="ts-contact-button text-main-alt ml-2"
                            >
                                <Typography variant="body2" sx={{ display: "flex", alignItems: "center" }}>
                                    Instagram
                                </Typography>
                            </a>
                        </Stack>
                    )}
                    {clinic.twitter && (
                        <Stack direction="row" spacing={1} alignItems="center" sx={{ my: 0.5 }}>
                            <SvgIconStyle
                                src={getPublicImage("ic_twitter_logo.svg")}
                                sx={{ color: theme.palette.grey[600] }}
                            />
                            <a
                                href={clinic.twitter}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="ts-contact-button text-main-alt ml-2"
                            >
                                <Typography variant="body2" sx={{ display: "flex", alignItems: "center" }}>
                                    Twitter
                                </Typography>
                            </a>
                        </Stack>
                    )}
                    {clinic.linkedin && (
                        <Stack direction="row" spacing={1} alignItems="center" sx={{ my: 0.5 }}>
                            <SvgIconStyle
                                src={getPublicImage("ic_linkedin_logo.svg")}
                                sx={{ color: theme.palette.grey[600] }}
                            />
                            <a
                                href={clinic.linkedin}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="ts-contact-button text-main-alt ml-2"
                            >
                                <Typography variant="body2" sx={{ display: "flex", alignItems: "center" }}>
                                    LinkedIn
                                </Typography>
                            </a>
                        </Stack>
                    )}
                </Stack>
            </>
        );
    };

    const ProviderList = ({ providers }) => {
        return (
            <>
                {providers &&
                    providers.length > 0 &&
                    providers.map((provider, idx) => {
                        if (provider && !provider.active) return null;

                        return (
                            <Grid container key={idx} className="hover-pointer my-1">
                                <Grid item xs={6}>
                                    <a
                                        href={`${generatePath(PROVIDER_PROFILE_ROUTE, { providerId: provider.id })}/${
                                            provider.slug
                                        }`}
                                        rel="canonical"
                                        title={`${provider.user.first_name} ${provider.user.last_name}'s profile`}
                                    >
                                        <Stack direction="row" height="32px" alignItems="center" spacing={1}>
                                            <TSAvatar
                                                image={provider && provider.photo}
                                                name={provider && provider.user && provider.user.first_name}
                                                size="xs"
                                            />
                                            {provider && provider.user && (
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        color: theme.palette.grey[600],
                                                        ml: 1,
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                    }}
                                                >
                                                    {provider.user.first_name} {provider.user.last_name}
                                                </Typography>
                                            )}
                                        </Stack>
                                    </a>
                                </Grid>
                                {provider && provider.profile_credentials && provider.profile_credentials.length > 0 && (
                                    <Grid item xs={6}>
                                        <Grid
                                            container
                                            spacing={1}
                                            sx={{ display: "flex", justifyContent: "flex-end", mt: "0px !important" }}
                                        >
                                            {provider &&
                                                provider.profile_credentials &&
                                                provider.profile_credentials.length > 0 &&
                                                provider.profile_credentials.map((cred, idx) => (
                                                    <Fragment key={idx}>
                                                        {cred && cred.credential && (
                                                            <Grid item sx={{ pt: "0px !important" }}>
                                                                <Chip
                                                                    label={cred.credential}
                                                                    sx={{
                                                                        fontWeight: 600,
                                                                        mt: "0px !important",
                                                                    }}
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Fragment>
                                                ))}
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        );
                    })}
            </>
        );
    };

    const LocationInfo = () => {
        return (
            <>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5" sx={{ fontWeight: 700, color: theme.palette.grey[800], mb: 1 }}>
                        Providers
                    </Typography>
                </Stack>
                {clinic.providers && clinic.providers.length > 0 && (
                    <Stack
                        sx={{
                            flexDirection: "row",
                            backgroundColor: theme.palette.grey[100],
                            borderRadius: "20px",
                            p: 2,
                        }}
                    >
                        <SvgIconStyle
                            src={getPublicImage("ic_location.svg")}
                            sx={{ alignSelf: "start", color: theme.palette.orange.main }}
                        />
                        <Stack sx={{ ml: 2, width: "100%" }}>
                            <Typography variant="body1" sx={{ color: theme.palette.grey[600], fontWeight: 700 }}>
                                {clinic.title}
                            </Typography>
                            <Typography variant="body2" sx={{ color: theme.palette.grey[600] }}>
                                {clinic.address1} {clinic.address2} {clinic.city}, {clinic.state} {clinic.zip}
                            </Typography>
                            <Typography
                                variant="headliner"
                                sx={{ color: theme.palette.grey[600], fontWeight: 700, mt: 2 }}
                            >
                                Providers at this location
                            </Typography>
                            <ProviderList providers={clinic.providers} />
                        </Stack>
                    </Stack>
                )}
            </>
        );
    };

    return (
        <Page
            title={`Myofascial Release Therapy Clinic in ${
                clinic && clinic.city && clinic.state && `${clinic.city}, ${clinic.state}`
            } by ${clinic && clinic.title}`}
            mfr={mfrDomain}
            mfrProvider={isMFR && me.is_coach}
            meta={[{ property: "keywords", content: metaKeywords }]}
        >
            {mfr.clinicIsFetching ? (
                <Stack sx={{ minHeight: "400px", alignItems: "center", justifyContent: "center" }}>
                    <Spinner />
                </Stack>
            ) : (
                <Container maxWidth="xl">
                    {me && !me.id && <MFRHeader />}
                    {clinic && !clinic.public && canEditProfile && (
                        <Alert severity="warning">
                            <AlertTitle>This profile is hidden</AlertTitle>This profile is currently hidden from public
                            view. Click the status button below when you are ready to publish.
                        </Alert>
                    )}
                    {clinic ? (
                        <Card sx={{ p: { xs: 3, lg: "75px" }, mt: !me.id ? "100px" : 2, mb: 2 }}>
                            {canEditProfile && (
                                <Stack sx={{ flexDirection: "row", justifyContent: "end" }}>
                                    <ButtonMain
                                        onClick={handleOpenMenu}
                                        sx={{
                                            backgroundColor: !clinic.public
                                                ? theme.palette.warning.main
                                                : theme.palette.primary.main,
                                            color: !clinic.public
                                                ? theme.palette.warning.contrastText
                                                : theme.palette.text.white,
                                            "&:hover": {
                                                backgroundColor: !clinic.public
                                                    ? theme.palette.warning.main
                                                    : theme.palette.primary.main,
                                                color: !clinic.public
                                                    ? theme.palette.warning.contrastText
                                                    : theme.palette.text.white,
                                            },
                                            padding: "8px 16px !important",
                                            fontWeight: 500,
                                        }}
                                    >
                                        <SvgIconStyle
                                            src={getPublicImage(clinic.public ? "ic_eye.svg" : "ic_eye_off.svg")}
                                            className="ts-edit-user-icon mr-2"
                                            alt="Toggle status"
                                        />
                                        {clinic.public ? "Public" : "Hidden"}
                                    </ButtonMain>
                                    <MenuPopover
                                        open={openMenu}
                                        anchorEl={openMenu}
                                        onClose={handleCloseMenu}
                                        anchorOrigin={{ vertical: "top", horizontal: "left" }}
                                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                                        disabledArrow
                                        sx={{
                                            mt: -1,
                                            width: 200,
                                            "& .MuiMenuItem-root": {
                                                px: 1,
                                                typography: "body2",
                                                borderRadius: 0.75,
                                                "& svg": { mr: 2, width: 20, height: 20 },
                                            },
                                        }}
                                    >
                                        {menuActions}
                                    </MenuPopover>
                                </Stack>
                            )}
                            <Stack sx={{ flexDirection: "row", justifyContent: "space-between", mt: 1, mb: 4 }}>
                                <Typography variant="caption" sx={{ fontSize: "2rem !important" }}>
                                    {clinic.group && clinic.group.title
                                        ? clinic.group.title.length < 40
                                            ? `${clinic.group.title} Myofascial Release Therapy in ${clinic.city}, ${clinic.state}`
                                            : clinic.group.title
                                        : `Myofascial Release Therapy Clinic in ${clinic.city}, ${clinic.state}`}
                                </Typography>
                                <Box alignSelf="center">
                                    <ShareButton profileId={clinic.id} clinic />
                                </Box>
                            </Stack>
                            {canEditProfile && (
                                <Stack sx={{ flexDirection: "row", justifyContent: "end" }}>
                                    <ButtonLink onClick={showEditProfileModal("keywords")}>Manage keywords</ButtonLink>
                                </Stack>
                            )}
                            <Stack sx={{ flexDirection: "row", display: { xs: "none", lg: "flex" }, mb: "85px" }}>
                                <Stack
                                    sx={{
                                        flexDirection: "row",
                                        alignItems: "start",
                                        display: { xs: "none", lg: "flex" },
                                        mb: "85px",
                                        mr: 4,
                                        width: "35%",
                                    }}
                                >
                                    {clinic && clinic.clinic_images && clinic.clinic_images.length > 1 ? (
                                        <Box sx={{ width: "100%", position: "relative" }}>
                                            <ImageCarousel imagesContainer={clinic.clinic_images} />
                                            {canEditProfile && (
                                                <Stack
                                                    className={cx(
                                                        clinic.clinic_images.length > 0
                                                            ? "ts-edit-profile-images-multiple"
                                                            : "ts-edit-profile-images-single",
                                                        "hover-pointer"
                                                    )}
                                                    onClick={showEditProfileModal("images")}
                                                >
                                                    <SvgIconStyle src={getPublicImage("ic_edit.svg")} />
                                                    <Typography variant="caption">Edit Image(s)</Typography>
                                                </Stack>
                                            )}
                                        </Box>
                                    ) : (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                width: "500px",
                                                // height: "50%",
                                                position: "relative",
                                                justifyContent:
                                                    clinic && clinic.clinic_images && clinic.clinic_images.length === 0
                                                        ? "center"
                                                        : "start",
                                            }}
                                        >
                                            {clinic && clinic.clinic_images && clinic.clinic_images.length === 0 ? (
                                                <TSAvatar name={clinic.title} size="lg" />
                                            ) : (
                                                <Image
                                                    src={
                                                        clinic &&
                                                        clinic.clinic_images &&
                                                        clinic.clinic_images.length > 0 &&
                                                        clinic.clinic_images[0].image
                                                    }
                                                    className="ts-single-clinic-image"
                                                    alt="Clinic Profile Image"
                                                />
                                            )}
                                            {canEditProfile && (
                                                <Stack
                                                    className={cx(
                                                        clinic.clinic_images.length > 0
                                                            ? "ts-edit-profile-images-multiple"
                                                            : "ts-edit-profile-images-avatar",
                                                        "hover-pointer"
                                                    )}
                                                    onClick={showEditProfileModal("images")}
                                                >
                                                    <SvgIconStyle src={getPublicImage("ic_edit.svg")} />
                                                    <Typography variant="caption">Edit Image(s)</Typography>
                                                </Stack>
                                            )}
                                        </Box>
                                    )}
                                </Stack>
                                <Stack sx={{ width: "100%", ml: clinic && clinic.profile_image ? 4 : 0 }}>
                                    <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
                                        <Typography variant="h1" sx={{ fontSize: "3rem !important", mr: 2 }}>
                                            {clinic.title}
                                        </Typography>
                                        {canEditProfile && <EditButton section="clinic-info" />}
                                    </Stack>
                                    {clinic.address1 && (
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={6}
                                                lg={12}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    height: "32px",
                                                    mt: { xs: 0, md: 1.5 },
                                                }}
                                            >
                                                <Typography variant="headline" sx={{ color: theme.palette.grey[600] }}>
                                                    {clinic.address1} {clinic.address2} {clinic.city}, {clinic.state}{" "}
                                                    {clinic.zip}
                                                </Typography>
                                            </Grid>
                                            {/* <Grid item xs={6}>
                                                <Grid
                                                    container
                                                    spacing={1}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "end",
                                                        mt: "0px !important",
                                                    }}
                                                >
                                                    {clinic &&
                                                    clinic.clinic_credentials &&
                                                    clinic.clinic_credentials.length > 0 ? (
                                                        clinic.clinic_credentials.map((c, idx) => {
                                                            const cred = PROVIDER_TYPES.find(
                                                                (t) => t.label === c.credential
                                                            ).value;
                                                            return (
                                                                <Grid item key={idx} sx={{ pt: "0px !important" }}>
                                                                    <Chip
                                                                        label={cred}
                                                                        sx={{
                                                                            fontWeight: 600,
                                                                            mt: "0px !important",
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            );
                                                        })
                                                    ) : (
                                                        <>
                                                            {canEditProfile && (
                                                                <Typography
                                                                    variant="headline"
                                                                    sx={{ fontStyle: "italic" }}
                                                                >
                                                                    Tell us what you specialize in
                                                                </Typography>
                                                            )}
                                                        </>
                                                    )}
                                                    <Grid item sx={{ pt: "0px !important" }}>
                                                        {canEditProfile && <EditButton section="clinic-credentials" />}
                                                    </Grid>
                                                </Grid>
                                            </Grid> */}
                                        </Grid>
                                    )}
                                    <Divider sx={{ mt: 3, mb: 1 }} />
                                    <Stack
                                        sx={{
                                            flexDirection: "row",
                                            alignItems: "start",
                                            mt: 2,
                                            justifyContent: !clinic.bio && "space-between",
                                        }}
                                    >
                                        {clinic.bio && clinic.bio.length > 0 ? (
                                            <Stack
                                                sx={{
                                                    flexDirection: canEditProfile ? "row" : "column",
                                                    color: theme.palette.grey[800],
                                                    alignItems: "start",
                                                    mr: canEditProfile && 1,
                                                }}
                                            >
                                                <Typography
                                                    variant="headline"
                                                    sx={{ color: theme.palette.grey[800], whiteSpace: "pre-wrap" }}
                                                >
                                                    {clinic.bio}
                                                </Typography>
                                            </Stack>
                                        ) : (
                                            <>
                                                {canEditProfile && (
                                                    <Typography variant="headline" sx={{ fontStyle: "italic" }}>
                                                        Tell us a little bit about your clinic
                                                    </Typography>
                                                )}
                                            </>
                                        )}
                                        {canEditProfile && <EditButton section="clinic-bio" />}
                                    </Stack>
                                    <Box sx={{ my: 2, display: { xs: "none", lg: "block" } }}>
                                        <ContactInfo />
                                    </Box>
                                    {clinic && (
                                        <Box sx={{ my: 2, display: { xs: "none", lg: "block" } }}>
                                            <LocationInfo />
                                        </Box>
                                    )}
                                    {clinic && center && (
                                        <Box sx={{ my: 2, display: { xs: "none", lg: "block" } }}>
                                            <GoogleMap mapContainerStyle={containerStyle} zoom={15} center={center}>
                                                <TSMarker clinic={clinic} disableInfo />
                                            </GoogleMap>
                                        </Box>
                                    )}
                                    {clinic &&
                                        clinic.clarifying_content_blurb &&
                                        clinic.clarifying_content_blurb.length > 0 && (
                                            <Box>
                                                <Typography
                                                    className={cx(
                                                        showBlurb ? "ts-clarifying-blurb" : "ts-clarifying-blurb-hidden"
                                                    )}
                                                >
                                                    {clinic.clarifying_content_blurb}
                                                </Typography>
                                                {!showBlurb && (
                                                    <Button
                                                        link
                                                        className="ts-clarifying-blurb-read-more"
                                                        onClick={toggleBlurb}
                                                    >
                                                        <span className="px-2 py-1">Read more</span>
                                                    </Button>
                                                )}
                                            </Box>
                                        )}
                                </Stack>
                            </Stack>
                            {/* Mobile layout */}
                            <Box sx={{ display: { xs: "block", lg: "none" } }}>
                                <div>
                                    <Stack flexDirection="row" justifyContent="space-between">
                                        <Typography variant="h3" sx={{ mt: 2 }}>
                                            {clinic.title}
                                        </Typography>
                                        {canEditProfile && <EditButton section="clinic-info" />}
                                    </Stack>
                                    {clinic.address1 && (
                                        <Typography variant="headline" sx={{ color: theme.palette.grey[600] }}>
                                            {clinic.address1} {clinic.address2} {clinic.city}, {clinic.state}{" "}
                                            {clinic.zip}
                                        </Typography>
                                    )}
                                </div>
                                <Divider sx={{ mt: 3, mb: 1 }} />
                                <Stack
                                    sx={{
                                        mb:
                                            clinic && clinic.clinic_images && clinic.clinic_images.length > 0
                                                ? "85px"
                                                : "40px",
                                        mt: 4,
                                        alignItems: canEditProfile ? "center" : "start",
                                        flexDirection: "row",
                                        justifyContent: {
                                            xs:
                                                clinic && clinic.clinic_images && clinic.clinic_images.length === 0
                                                    ? "center"
                                                    : "space-between",
                                            md: "center",
                                        },
                                    }}
                                >
                                    {clinic && clinic.clinic_images && clinic.clinic_images.length > 1 ? (
                                        <Box
                                            sx={{
                                                width: {
                                                    xs: canEditProfile ? "90%" : "100%",
                                                    sm: canEditProfile ? "95%" : "100%",
                                                    md: "50%",
                                                },
                                                position: "relative",
                                            }}
                                            className="ts-clinic-image-carouel"
                                        >
                                            <ImageCarousel imagesContainer={clinic.clinic_images} />
                                            {canEditProfile && (
                                                <Stack
                                                    className={cx(
                                                        clinic.clinic_images.length > 0
                                                            ? "ts-edit-profile-images-multiple"
                                                            : "ts-edit-profile-images-single",
                                                        "hover-pointer"
                                                    )}
                                                    onClick={showEditProfileModal("images")}
                                                >
                                                    <SvgIconStyle src={getPublicImage("ic_edit.svg")} />
                                                    <Typography variant="caption">Edit Image(s)</Typography>
                                                </Stack>
                                            )}
                                        </Box>
                                    ) : (
                                        <Box
                                            sx={{
                                                width: "500px",
                                                display: "flex",
                                                position: "relative",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            {clinic && clinic.clinic_images && clinic.clinic_images.length === 0 ? (
                                                <TSAvatar name={clinic.title} size="lg" />
                                            ) : (
                                                <Image
                                                    src={
                                                        clinic &&
                                                        clinic.clinic_images &&
                                                        clinic.clinic_images.length > 0 &&
                                                        clinic.clinic_images[0].image
                                                    }
                                                    className="ts-single-clinic-image"
                                                    alt="Clinic image"
                                                />
                                            )}
                                            {canEditProfile && (
                                                <Stack
                                                    className={cx(
                                                        clinic.clinic_images.length > 0
                                                            ? "ts-edit-profle-images-avatar"
                                                            : "ts-edit-profile-images-overlay",
                                                        "hover-pointer"
                                                    )}
                                                    onClick={showEditProfileModal("images")}
                                                >
                                                    <SvgIconStyle src={getPublicImage("ic_edit.svg")} />
                                                    <Typography variant="caption">Edit Image(s)</Typography>
                                                </Stack>
                                            )}
                                        </Box>
                                    )}
                                </Stack>
                            </Box>
                            <Stack
                                sx={{
                                    display: {
                                        xs: "flex",
                                        lg: "none",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "start",
                                    },
                                }}
                            >
                                {clinic.bio && clinic.bio.length > 0 ? (
                                    <Typography
                                        variant="headline"
                                        sx={{ color: theme.palette.grey[800], whiteSpace: "pre-wrap" }}
                                    >
                                        {clinic.bio}
                                    </Typography>
                                ) : (
                                    <>
                                        {canEditProfile && (
                                            <Typography variant="headline" sx={{ fontStyle: "italic" }}>
                                                Tell us a little bit about your clinic
                                            </Typography>
                                        )}
                                    </>
                                )}
                                {canEditProfile && <EditButton section="clinic-bio" />}
                            </Stack>
                            <Stack sx={{ my: 2, display: { xs: "block", lg: "none" } }}>
                                <ContactInfo />
                            </Stack>
                            {clinic && (
                                <Stack sx={{ my: 2, display: { xs: "block", lg: "none" } }}>
                                    <LocationInfo />
                                </Stack>
                            )}
                            {clinic && center && (
                                <Stack sx={{ mt: 4, mb: 2, display: { xs: "block", lg: "none" } }}>
                                    <GoogleMap mapContainerStyle={containerStyle} zoom={15} center={center}>
                                        <TSMarker clinic={clinic} disableInfo />
                                    </GoogleMap>
                                </Stack>
                            )}
                        </Card>
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                minHeight: "400px",
                                width: "100%",
                            }}
                        >
                            <Spinner />
                        </Box>
                    )}
                    {/* Wait for clinic to exist */}
                    {clinic && clinic.title && (
                        <ProviderContactForm show={showContactForm} clinic={clinic} toggleShow={toggleContactForm} />
                    )}

                    <EditClinicImagesModal
                        show={showEditClinicImagesModal}
                        clinic={clinic}
                        handleClose={closeEditModal}
                    />
                    <EditClinicInfoModal show={showEditClinicInfoModal} clinic={clinic} handleClose={closeEditModal} />
                    <EditKeywordsModal show={showEditKeywordsModal} clinic={clinic} handleClose={closeEditModal} />
                    <EditBioModal show={showEditClinicBioModal} clinic={clinic} handleClose={closeEditModal} />
                    <EditClinicContactModal
                        show={showEditClinicContactModal}
                        clinic={clinic}
                        handleClose={closeEditModal}
                    />
                    <EditCredentialsModal
                        show={showEditCredentialsModal}
                        credentials={clinic && clinic.clinic_credentials}
                        handleClose={closeEditModal}
                        clinicId={clinic && clinic.id}
                    />
                    <Modal
                        show={showUnpublishedModal}
                        content={
                            clinic && clinic.providers && clinic.providers.length === 0
                                ? "To be searchable on the MFR directory, you need to assign providers and publish your clinic. Would you like to assign yourself and publish your clinic?"
                                : "To be searchable on the MFR directory, you need to publish your clinic. Would you like to publish your clinic?"
                        }
                        toggleShow={closeUnpublishModal}
                        actions={[
                            <Button
                                key="no-button"
                                linkDefault
                                square
                                className="p-3 mr-2"
                                onClick={closeUnpublishModal}
                            >
                                Leave unpublished
                            </Button>,
                            <Button key="yes-button" compact secondary square onClick={publishClinic}>
                                {clinic && clinic.providers && clinic.providers.length === 0
                                    ? "Assign and Publish"
                                    : "Publish"}
                            </Button>,
                        ]}
                    />
                </Container>
            )}
        </Page>
    );
}
