import { applyMiddleware, createStore, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { asyncDispatchMiddleware } from "./middleware";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "./reducers";
import reduxWebsocket from "@giantmachines/redux-websocket";

const loggerMiddleware = createLogger({
    collapsed: true,
});

export const history = createBrowserHistory();
const reduxWebsocketMiddleware = reduxWebsocket({
    reconnectOnClose: true,
    deserializer: JSON.parse,
});

// const store = createStore(rootReducer, applyMiddleware(thunkMiddleware, loggerMiddleware, asyncDispatchMiddleware));
// export default store;

export default function configureStore(preloadedState) {
    const store = createStore(
        createRootReducer(history), // root reducer with router state
        preloadedState,
        compose(
            applyMiddleware(
                routerMiddleware(history), // for dispatching history actions
                thunkMiddleware,
                loggerMiddleware,
                asyncDispatchMiddleware,
                reduxWebsocketMiddleware
            )
        )
    );

    return store;
}
